div.user_termList.list-group{
   width:fit-content;
   height: 100vh;
   text-align:end;
}

div.user_termlistCol.col{
   height:60vh;
   overflow:scroll;
}

button.User_addTermButton.btn.btn-secondary.btn-sm{
   margin-left:1rem;
   background-color: white;
   color: black;
}

button.User_removeTermButton.btn.btn-secondary.btn-sm{
   margin-left:1rem;
   background-color: white;
   color: black;
}

.user_termAddedCol.col{
   height:60vh;
   overflow:scroll;
}

div.user_termSelected.list-group{
   width:fit-content;
   height: 100vh;
   text-align:end;
   float:right;
}

div.col-sm-5{
   background-color:white;
   border-radius:1%;
   margin-left:2rem;
}

nav.nav.nav-tabs{
   margin-bottom:1rem;
}

div.User_SearchCol.col-sm-2{
   margin-left:10rem; 
   background-color:white;
   border:1px solid snow;
   border-radius: 1%;
   padding-top:1rem;
   text-align:center;
   height:fit-content;
   padding-left:2rem;
   padding-right:2rem;
   padding-bottom:1rem;
}

.User_nameSearch{
   margin-bottom:1rem;
}

h5{
   margin-bottom:1rem;
}

div.tab-content{
   padding-left:1rem;
   padding-right:1rem;
}

div.User_saveUserbtn.row{
   float:right;
   margin:1rem;
}