/*  <div class="truckBroke-wrapper" style={{position:"relative", zIndex: 2, marginTop: "235px"}}>
    <div class="truckBroke">
      <div class="truck-container"><img style={{width:"65px", paddingLeft:"5px", paddingTop:"15px"}} src="https://trtrateguide.blob.core.windows.net/public/CPG_Logo.jpg"/></div>
        <div class="glases"></div>
        <div class="bonet"></div>
        <div class="base"></div>
        <div class="base-aux"></div>
        <div class="wheel-back"></div>
        <div class="wheel-front"></div>
        <div class="smoke"></div>
        <div class="smoke2"></div>
        <div class="smoke3"></div>
        <div class="smoke4"></div>
      </div>   */

.truckBroke-wrapper{
  height: 200px;
  width: 200px;
  position:absolute;
  top:50%;
  left:50%;
  transform:translateX(-50%) translateY(-50%) scale(1.5);
  background:inherit;
  overflow:hidden;
}

.truckBroke{
  height:110px;
  width:150px;
  position:absolute;  
  bottom:48px;
  left: calc(50% + 10px);
  transform: translateX(-50%);  
}

.truckBroke > .truck-container {
  height:54.4px;
  border: 1px solid black;
  width:83px;
  position:absolute;
  top:10px;
  left:10px;
  background-color:white;  
}

.truckBroke > .glases{
  background: rgb(40,181,245);
  background: -moz-linear-gradient(-45deg, rgba(40,181,245,1) 0%, rgba(40,181,245,1) 50%, rgba(2,153,227,1) 52%, rgba(2,153,227,1) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(40,181,245,1) 0%,rgba(40,181,245,1) 50%,rgba(2,153,227,1) 52%,rgba(2,153,227,1) 100%);
  background: linear-gradient(135deg, rgba(40,181,245,1) 0%,rgba(40,181,245,1) 50%,rgba(2,153,227,1) 52%,rgba(2,153,227,1) 100%);
  position:absolute;
  height:30px;
  width:33px;
  border:4px solid #010440;
  border-bottom:none;
  top:35px;
  left:95px;
  border-top-right-radius:6px;
}

.truckBroke > .glases:after{
  content:'';
  display:block;
  background-color:#010440;
  height:13px;
  width:10px;
  position:absolute;
  right:-10px;
  bottom:0px;
  border-radius:10px / 15px;
  border-bottom-right-radius:0px;
  border-bottom-left-radius:0px;
  border-top-left-radius:0px;
}

.truckBroke > .glases:before{
  content:'';
  display:block;
  background-color:#010440;
  height:12px;
  width:16px;
  position:absolute;
  left:0px;
  bottom:0px;
  border-top-right-radius:4px;
}

.truckBroke > .bonet{
  background-color:#010440;
  position:absolute;
  width:124px;
  height:15px;
  top:64px;
  left:10px;
  z-index:-1;
}

.truckBroke > .bonet:after{
  content:'';
  display:block;
  background: rgb(255,255,255);
  background: -moz-linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 50%, rgba(225,225,225,1) 51%, rgba(246,246,246,1) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(255,255,255,1) 0%,rgba(241,241,241,1) 50%,rgba(225,225,225,1) 51%,rgba(246,246,246,1) 100%);
  background: linear-gradient(135deg, rgba(255,255,255,1) 0%,rgba(241,241,241,1) 50%,rgba(225,225,225,1) 51%,rgba(246,246,246,1) 100%);
  height:10px;
  width:6px;
  position:absolute;
  right:0px;
  bottom:2px;
  border-top-left-radius:4px;
}

.truckBroke > .base{
  position:absolute;
  background-color:#445A64;
  width:106px;
  height:15px;
  border-top-right-radius:10px;
  top:70px;
  left:14px;
}

.truckBroke > .base:before{
   content:'';
  display:block;
  background-color:#E54A18;
  height:12px;
  width:5px;
  position:absolute;
  left:-4px;
  bottom:0px;
  border-bottom-left-radius:4px;
}

.truckBroke > .base:after{
  content:'';
  display:block;
  background-color:RGB(84,110,122);
  height:10px;
  width:20px;
  position:absolute;
  right:-16px;
  bottom:0px;
  border-bottom-right-radius:4px;
  z-index:-1;
}

.truckBroke > .base-aux{
  width:82px;
  height:8px;
  background-color:#010440;
  position:absolute;
  top:65px;
  left:14px;
  border-bottom-right-radius:4px;
}

.truckBroke > .wheel-back{
  left:20px
}

.truckBroke > .wheel-front{
  left:95px;
}

.truckBroke > .wheel-back,.truckBroke > .wheel-front{
  border-radius:100%;
  position:absolute;
  background: rgb(84,110,122);
  background: -moz-linear-gradient(-45deg, rgba(84,110,122,1) 0%, rgba(84,110,122,1) 49%, rgba(68,90,100,1) 52%, rgba(68,90,100,1) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(84,110,122,1) 0%,rgba(84,110,122,1) 49%,rgba(68,90,100,1) 52%,rgba(68,90,100,1) 100%);
  background: linear-gradient(135deg, rgba(84,110,122,1) 0%,rgba(84,110,122,1) 49%,rgba(68,90,100,1) 52%,rgba(68,90,100,1) 100%);
  top:80px;
  height:22px;
  width:22px;
}

.truckBroke > .wheel-back:before,.truckBroke > .wheel-front:before{
  content:'';
  border-radius:100%;
  left:5px;
  top:5px;
  position:absolute;
  background: rgb(175,189,195);
  background: -moz-linear-gradient(-45deg, rgba(175,189,195,1) 0%, rgba(175,189,195,1) 50%, rgba(143,163,173,1) 51%, rgba(143,163,173,1) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(175,189,195,1) 0%,rgba(175,189,195,1) 50%,rgba(143,163,173,1) 51%,rgba(143,163,173,1) 100%);
  background: linear-gradient(135deg, rgba(175,189,195,1) 0%,rgba(175,189,195,1) 50%,rgba(143,163,173,1) 51%,rgba(143,163,173,1) 100%);
  height:12px;
  width:12px; 
}

.truckBroke > .smoke{
  content:'';
  position:relative;
  background-color:#AFBDC3;
  border-radius:100%;
  margin-top:3px;
  width:8px;
  height:8px;
  top:53px;
  left:143px;
  -webkit-animation:fadeBroke 2s linear infinite;
  animation: fadeBroke 2s linear infinite;
  opacity:0;
}

.truckBroke > .smoke:after{
  content:'';
  position:absolute;
  background-color:RGB(143,163,173);
  border-radius:100%;
  width:6px;
  height:6px;
  top:5px;
  left:0px;
}

.truckBroke > .smoke:before{
  content:'';
  position:absolute;
  background-color:RGB(143,163,173);
  border-radius:100%;
  width:4px;
  height:4px;
  top:10px;
  left:0px;
}

.truckBroke > .smoke2{
  content:'';
  position:absolute;
  background-color:#AFBDC3;
  border-radius:100%;
  width:8px;
  height:8px;
  top:53px;
  left:141px;
  -webkit-animation:fadeBroke 3s linear infinite;
  animation: fadeBroke2 3s linear infinite;
  opacity:0;
}

.truckBroke > .smoke2:after{
  content:'';
  position:absolute;
  background-color:RGB(143,163,173);
  border-radius:100%;
  width:6px;
  height:6px;
  top:5px;
  left:0px;
}

.truckBroke > .smoke2:before{
  content:'';
  position:absolute;
  background-color:RGB(143,163,173);
  border-radius:100%;
  width:4px;
  height:4px;
  top:10px;
  left:0px;
}

.truckBroke > .smoke3{
  content:'';
  position:absolute;
  background-color:#AFBDC3;
  border-radius:100%;
  width:8px;
  height:8px;
  top:50px;
  left:140px;
  opacity:0.4;
}

.truckBroke > .smoke3:after{
  content:'';
  position:absolute;
  background-color:RGB(143,163,173);
  border-radius:100%;
  width:6px;
  height:6px;
  top:5px;
  left:0px;
}

.truckBroke > .smoke3:before{
  content:'';
  position:absolute;
  background-color:RGB(143,163,173);
  border-radius:100%;
  width:4px;
  height:4px;
  top:10px;
  left:0px;
}

.truckBroke > .smoke4{
  position:absolute;
  background-color:#AFBDC3;
  border-radius:100%;
  width:8px;
  height:8px;
  top:45px;
  left:135px;
  opacity:0.4;
}

.truckBroke > .smoke4:after{
  content:'';
  position:absolute;
  background-color:RGB(143,163,173);
  border-radius:100%;
  width:6px;
  height:6px;
  top:5px;
  left:0px;
}

.truckBroke > .smoke4:before{
  content:'';
  position:absolute;
  background-color:RGB(143,163,173);
  border-radius:100%;
  width:4px;
  height:4px;
  top:10px;
  left:0px;
}

@keyframes fadeBroke {
  0%{
    opacity:0;
    top:45px;
  }
  
  10%{
    opacity:1;
    top:45px;
  }
  
  20%{
    opacity:.8;
    top:40px;
  }
  
  30%{
    opacity:0.7;
    top:35px;
  }
  
  40%{
    opacity:0.6;
    top:30px;
  }
  
  50%{
    opacity:0.5;
    top:25px;
  }
  
  60%{
    opacity:0.4;
    top:20px;
  }
  
  70%{
   opacity:0.3;
    top:15px;
  }
  
  80%{
   opacity:0.2;
    top:10px;
  }
  
  90%{
    opacity:0.1;
    top:5px;
  }
  
  100%{
    opacity:0;
    top:0px
  }
}

@keyframes fadeBroke2 {
  0%{
    opacity:0;
    top:45px;
  }
  
  30%{
    opacity:0.3;
    top:45px;
  }
  
  50%{
    opacity:0.5;
    top:40px;
  }
  
  70%{
   opacity:0.1;
    top:45px;
  }
  
  90%{
    opacity:0.4;
    top:40px
  }
  
  100%{
    opacity:0;
    top:45px
  }
}