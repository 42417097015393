.formDetail {
    border: 1px solid;
    padding: 10px;
    box-shadow: 5px 10px #888888;
    height: 100%;
    width: 100%;
    position: fixed;
  }

  .termList {
    height:80vh;
    overflow: scroll
  }

  div.termListCol.col-sm-2{

    padding-right:0px;
    margin-right:0.8rem;
  }

  div.termList.list-group{
    width:max-content;
  }

  div.term_mainCol.col-sm-6{
    height:85vh;
    margin-right:1.8rem;
    margin-left:2rem;
  }

  div.Term_saveRow.row{
    float:right;
    margin-right:1rem;
  }

  div.Term_SearchCol.col-sm-2{
    background-color:white;
    border:1px  solid snow;
    border-radius:1%;
    padding-top:1rem;
    text-align:center;
    height:fit-content;
    padding-left:2rem;
    padding-right:2rem;
    padding-bottom:1rem
  }

 .form-control-file{
    margin-bottom:1rem;
  }


  div.user_termList.list-group{
    width:fit-content;
    height: 100vh;
    text-align:end;
}

div.user_termlistCol.col{
    height:60vh;
    overflow:scroll;
}

button.User_addTermButton.btn.btn-secondary.btn-sm{
    margin-left:1rem;
    background-color: white;
    color: black;
}

button.User_removeTermButton.btn.btn-secondary.btn-sm{
    margin-left:1rem;
    background-color: white;
    color: black;
}

.user_termAddedCol.col{
    height:60vh;
    overflow:scroll;
   
}

div.user_termSelected.list-group{
    width:fit-content;
    height: 100vh;
    text-align:end;
    float:right;
}
