div.Fuel_SearchCol.col-sm-2{
   background-color:white;
   border:1px  solid snow;
   border-radius:1%;
   padding-top:1rem;
   text-align:center;
   height:fit-content;
   padding-left:2rem;
   padding-right:2rem;
   padding-bottom:1rem;
}

 button.cpmButton.btn.btn-primary{
   border-radius: 0.50rem!important;
   margin-right: 1rem;
 }

 button.pprButton.btn.btn-primary{
   border-radius: 0.50rem;
}

div.selectedFuelRow.row{
   text-align-last: center;
   margin-left:1rem;  
}

div.fuelEditHeader.row{
   margin-left:0rem;
   margin-bottom:1rem;
}

div.selectCustRow.row{
   margin-top:3rem;
}

h2.tariffTermCustHeader{
   margin-bottom:1rem;
   text-align:center;
   -webkit-text-stroke: thin;
   background-color:#010440;
   color:white;
}

.tariff_termHeaders.row{
   margin-top:3rem;
}

div.termListAvail.list-group{
   height:50vh;
   overflow:auto;  
}

div.termListAdd.list-group{
   height:50vh;
   overflow:auto;
}

label.terminalsAvailableLabel.form-label{
   font-size: x-large;
   width: -webkit-fill-available;
   text-align: center;
}

label.terminalAddedLabel.form-label{
   font-size: x-large;
   width: -webkit-fill-available;
   text-align: center;
}

div.tariff_termlists.form-row{
   padding-left:1rem;
}

div.cpmppr_clear.col-1{
   margin-top:2.1rem;
   margin-left:2rem;
}

div.tariff_custDropdown.form-row{
   place-content:center;
}

div.backButton.col{
   padding-top:1rem;
}

div.saveButton.col{
   padding-right:2rem;
   padding-bottom:1rem;
}

button.saveButton_Tariff.btn.btn-primary{
   float:right;
}

div.selectedFuelRow.form-row{
   place-content:center;
   margin-top:-2rem;
   margin-bottom:3rem;
   -webkit-text-stroke: thin;
}

tbody{
   text-align:center;
}

thead{
   text-align:center;
}

div.overUnder_add.row{
   float:right;
   margin-right:1rem;
   margin-top:3rem;
}

div.form-row{
   margin-bottom:3rem;
}

h3.header-OverUnder{
   text-align:center;
   margin-bottom:3rem;
   margin-top:1rem;
   -webkit-text-stroke: thin;
   background-color:#010440;
   color:white;
   padding-top:0.20rem;
   padding-bottom:0.20rem;
}

div.TariffTable.col{
   background-color:white;
   border: 2px solid whitesmoke;
   padding-top:2rem;
   margin-right:1rem;
}

div.saveAllCol.col{
   text-align:center;
   margin-top:2rem;
   margin-bottom:1rem;
}