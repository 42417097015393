.modal-create-message {
   .content__input-message {
      width: 100%;
   }
   .content__select {
      width: 45%;
   }
   .message-date-section {
      display: flex;
      justify-content: space-between;
      padding: 0px 0px 20px 0px;
   }
   .actions {
      padding: 8px 30px 16px 30px;
   }
   .day-of-the-week {
      display: flex;
      flex-direction: row;
      & .MuiFormControlLabel-root {
         width: 128px;
      }
   }
   .text--divider {
      font-size: 0.7rem;
      color: #666666;
   }
   .error-message {
      color: #f44336;
      font-size: 0.75rem;
      font-weight: 500;
      margin-top: 3px;
      margin-bottom: 3px;
      margin-left: 0px;
   }
}
