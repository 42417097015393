.layoutMain {
   margin-top: 2rem;
   position: fixed;
   width: inherit;
   height: 100%;
   .layoutBody {
      position: absolute;
      height: calc(100% - 9.5rem);
      width: 100%;
      overflow-y: scroll;
   }
   .layoutBodyNarrow {
      position: absolute;
      height: calc(100% - 12.5rem);
      width: 100%;
      overflow-y: scroll;
   }
   .secondaryHeader {
      border-bottom: 1px solid #2f3136;
      z-index: 3;
      width: 100%;
      padding: 0px 16.797px 0px 24px;
   }
   .header--save-button {
      float: right;
      font-size: 2rem;
      margin-right: 0.25rem;
   }
   .paper-divisor {
      margin-bottom: 18px;
   }
   /* */
   .app-bar {
      box-shadow: none;
      border-bottom: 1px solid black;
      .MuiButtonBase-root {
         padding: 1.25rem 2.25rem;
      }
      .MuiTabs-indicator {
         height: 4px;
      }
      .tab-item {
         font-size: 1rem;
         font-weight: 700;
         letter-spacing: 0px;
         text-transform: none;
      }
   }

   .reader-filter {
      display: flex;
      margin-left: 25px;
      padding-top: 5px;
   }

   .edit-content {
      padding: 25px;
      .edit-content--row {
         display: flex;
         align-items: end;
      }
      .space-between-row {
         padding-bottom: 20px;
      }
      .content__select {
         min-width: 240px;
         padding-right: 40px;
      }
      .edit-content--checkbox {
         display: flex;
         flex-direction: row;
      }
      .edit-content--checkbox-item {
         min-width: 120px;
      }
      .content__input-message {
         width: 100%;
      }
      .text--divider {
         font-size: 0.9rem;
         color: #666666;
      }
      .day-of-the-week {
         display: flex;
         flex-direction: row;
         & .MuiFormControlLabel-root {
            width: 128px;
         }
      }
   }
   .container-activity-tab {
      padding-top: 30px;
   }
   .error-message {
      color: #f44336;
      font-size: 0.75rem;
      font-weight: 500;
      margin-top: 3px;
      margin-left: 0px;
   }
}
