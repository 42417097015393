.terminal-rates-modal {
   .dialog-title {
      background-color: #002d72;
      color: white;
      text-align: center;
   }
   .dialog-content {
      padding: 20px 30px;
   }
   .actions {
      padding: 8px 18px 16px 18px;
   }
}
